.programa-mercado {
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 8px;
  box-sizing: border-box;

  &__alert {
    display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);

    &--open {
      display: block;
    }

    &--error {

      .programa-mercado__alert__message__text {
        color: red;
      }

    }

    &__message {
      width: 344px;
      height: 141px;
      top: 50%;
      left: 50%;
      background: #FFFFFF;
      box-shadow: 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%), 0px 3px 5px rgb(0 0 0 / 20%);
      border-radius: 4px;
      box-sizing: border-box;
      margin-left: -172px;
      margin-top: -70px;
      padding: 18px;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #000000;

      &__title {
        font-weight: 700;
        margin-bottom: 20px;
      }

      &__text {
        margin-bottom: 20px;
      }

      &__buttons {
        text-align: right;

        button {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          letter-spacing: 0.0125em;
          text-transform: uppercase;
          color: #2F80ED;
          background: none;
          border: none;
        }

        &__close {

          &--secondary {

            color: #666666 !important;

          }

        }

      }
    }
  }
}
