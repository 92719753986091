.cobertura {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 8px;
  box-sizing: border-box;

  &__lista {
    margin-top: 8px;

    &__card {
      background: #FFFFFF;
      box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 15px;

      &__head {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        padding: 3px 30px;

        &--incomplete {
          background: #FF6F71;
        }

        &--complete {
          background: #6FCF97;
        }
      }

      &__container {
        padding: 11px 30px;
        padding-bottom: 16px;
        box-sizing: border-box;

        &__image {
          width: 100%;
          margin-bottom: 9px;

          img {
            width: 100%;
          }
        }

        &__title {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #191419;
          margin-bottom: 16px;
        }

        &__button {
          background: #FFFF00;
          box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
          border-radius: 4px;
          border: none;
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          color: #000000;
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}