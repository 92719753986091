
.containerBMax {
  position: fixed;
  top: 0px;
  bottom:0px;
  left: 0px;
  right: 0px;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  flex-direction: column;
  z-index: 15;
}

.blockBMax {
  position: fixed;
  top: 0px;
  bottom:0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  flex-direction: column;
}

.modalDetails {
    width: 100%;
    max-width: 500px;
    height: 285px;
    border-radius: 15px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo_club {
  width: 180px;
  height: 180px;
  background-image: url('../../../assets/icons/icon-club-max-home.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.titleStyle {
  font-weight: 700;
  font-size: 24px;
  color: black;
}

.headerBmax {
  width: 100%;
  display: flex;
}

.headerBmaxLeft {
  display: flex;
  flex: .9;
}

.headerBmaxRigth {
  display: flex;
  justify-content: flex-end;
  flex: .1;
}

.closeButton {
  font-size: 24px;
  color: gray;
  font-weight: bold;
  right: 20px;
}

.closeButtonContainer {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: #F0ECFC;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: 4px;
  margin-bottom: 15px;-webkit-box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
  -moz-box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
  box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
}

.termnsDivider {
  padding: 10px;
  padding: 10px;
  width: 100%;
}

.termnsCheck {
   accent-color: #FFFF00;
}

.termnsBtn {
  font-weight: bold; 
  color: black;
}

.brochuereBmax {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 8px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  background-color: white;
  border-top-right-radius: 10px;
  height: 95%;
  .maz-tu-info-bees-title {
    margin-bottom: 34px;
    padding: 0 !important;
    margin-top: 19px;
  }

  .maz-tu-info-bees-button {
    margin-bottom: 24px;
  }

  &__image {
    width: 100%;
    padding: 28px; 
    img {
      width: 100%;
      margin-bottom: 15px;-webkit-box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
      -moz-box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
      box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
    }
  }
}
