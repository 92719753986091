.maz-tu-info-bees-card-info {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;

  &__head {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
    background: #FFFF00;
    box-sizing: border-box;
    padding: 2px 17px;
  }

  &__columns {
    margin: 15px 0;
    display: flex;
    justify-content: space-around;
    height: 132px;

    &__column {
      width: 100%;
      min-width: 132px;
      height: 100%;
      text-align: center;
      position: relative;
      border-right: 2px solid #F0ECFC;

      &__image {

        &__level {
          width: 86px;
          height: 86px;
          background: #FFFF00;
          border: 2px solid #000000;
          font-family: 'Barlow';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 140%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: right;
          color: #000000; 
          border-radius: 45px;
          margin: 0 auto;
        }

        &.bmax {
          width: 88px;
          margin: 0 auto;
        }

        &__hex {
          background-image: url('./assets/hex-background2.svg');
          background-size: 130px;
          background-position-x: calc(50% + 10px);
          background-position-y: center;
          background-repeat: no-repeat;
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 10px;
          font-weight: 700;
          line-height: 12px;

          &__column {
            width: 50%;
            height: calc(100% - 5px);

            &--left {
              text-align: right;
              padding-right: 5px;
              border-right: 1px solid grey
            }

            &--right {
              text-align: left;
              padding-left: 5px;
            }

            &__row {
              &.font-small {
                &--1 { /* 5-digit number*/
                  span:nth-child(1) {
                    font-size: 9px;
                  }
                }
                &--2 { /* 6-digit number*/
                  span:nth-child(1) {
                    font-size: 8px;
                  }
                }
                &--3 {/* 7-digit number*/
                  span:nth-child(1) {
                    font-size: 7px;
                  }
                }
              }

              &.top-fixed {
                &--1 {
                  span {
                    top: 3px;

                    &:nth-child(2) {
                      top: -2px !important;
                    }
                  }
                }
                &--2 {
                  span {
                    top: 3px;
                    &:nth-child(2) {
                      top: -3px !important;
                    }
                  }
                }

                &--3 {
                  span {
                    top: 2px;
                    &:nth-child(2) {
                      top: -4px !important;
                    }
                  }
                }

                &--4 {
                  span {
                    top: -1px;
                    &:nth-child(2) {
                      top: -6px !important;
                    }
                  }
                }
              }

              span {
                display: inline-block;
                width: 100%;
                line-height: 12px;

                &:nth-child(2) {
                  font-size: 6px;
                  font-weight: 400;
                  position: relative;
                  top: -5px;
                } 
              }

              &--white {
                color: #ffffff;
              }

            }
          }
        }

      }

      &:nth-child(1) {

        img {
          width: 81px;
        }
  
      }

      &:nth-child(2) {

        img {
          width: 105px;
        }
  
      }

      &:nth-child(3) {
        border-right: none;

        img {
          width: 86px;
        }
  
      }

      &.no-border {
        border: none;
      }

      &__label {
        position: absolute;
        width: 100%;
        bottom: 0;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }
    }

  }
}