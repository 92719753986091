.registro {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 39px;
  padding-top: 40px;
  box-sizing: border-box;
  --maz-tu-info-bees-input-margin-bottom: 18px;

  .maz-tu-info-bees-title {
    padding: 0;
    margin: 8px 0;
  }

  &__button {
    width: 100%;
    text-align: right;
    --maz-tu-info-bees-button-width: 200px;
    margin-top: 48px;
  }

  &--exito {
    padding: 0 8px;
    padding-top: 40px;

    .maz-tu-info-bees-title {
      padding: 0 31px;
      margin: 8px 0;
      margin-bottom: 55px;
    }
  }

  &__success-card {
    background-image: url('./../../assets/images/bee.svg');
    background-repeat: no-repeat;
    background-position: center top 31px;
    background-color: #A7A8A9;
    box-sizing: border-box;
    padding: 124px 62px 21px 62px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 82px;
  }
}