.indicators {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;

  div {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #546DD4;
    margin-right: 5px;

    &.active {
      width: 10px;
      height: 10px;
      background-color: #546DD4;
      border-radius: 50%;
    }
  }
}
