.maz-tu-info-bees-card-progress {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 13px 20px;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;

  &__chevron {
    width: 10px;
    height: 16px;
    background-image: url('./assets/icon-chevron.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px 16px;
    position: absolute;
    top: 21px;
    right: 22px;
  }

  &__title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 3px; 
  }

  &__description {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 12px;
  }

  &__progress {

    &__title {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }

    &__bar {
      background: #F3F3F3;
      box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.2);
      border-radius: 40px;
      width: 100%;
      overflow: hidden;
      margin-bottom: 16px;

      &--coverage {
        height: 12px;
      }

      &--billing {
        height: 40px;
      }

      &__content {
        box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.2);
        height: 100%;
        overflow: hidden;
        position: relative;

        &__progress-billing {
          height: 40px;
          position: absolute;
          top: 0;
          background: linear-gradient(93.61deg, #FF9900 -3.91%, #FF6737 -3.9%, #FFC700 92.8%);
          box-shadow: inset 0 3px 3px rgb(0 0 0 / 20%);
        }

        &--coverage {
          background: linear-gradient(89.63deg, #003E9B -39.62%, #4AF4FF 114.28%);
        }

        &--billing {
          display: table;
        }

        &__division {
          display: table-cell;
          border-right: 1px solid #A7A8A9;
          position: relative;

          &:last-child {
            border: none;
          }

          &:nth-child(1) {
            .maz-tu-info-bees-card-progress__progress__bar__content__division__progress {
              background-color: #FFB042;
            }
          }

          &:nth-child(2) {
            .maz-tu-info-bees-card-progress__progress__bar__content__division__progress {
              background-color: #FFB042;
              opacity: 0.6;
            }
          }

          &:nth-child(3) {
            .maz-tu-info-bees-card-progress__progress__bar__content__division__progress {
              background-color: #FFFF00;
            }
          }

          &__progress {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 1;
            box-shadow: inset 0px 3px 3px rgb(0 0 0 / 20%);
          }

          &__amount {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #000000;
            margin-top: 7px;
            position: relative;
            z-index: 2;
          }

          &__points {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.025em;
            color: #000000;
            text-align: center;
            position: relative;
            z-index: 2;
          }
        }
      }

    }

  }

  &__bottom-label {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }
}