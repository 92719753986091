.maz-tu-info-bees-card-challenge {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  padding: 15px 30px;
  position: relative;
  box-sizing: border-box;
  padding-top: 35px;
  margin-bottom: 16px;

  &__title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background-color: #A7A8A9;
    padding: 3px 30px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 11px;
    position: absolute;
    top: 0;
    left: 0;

    &--incomplete {
      background-color: #FF6F71;
    }

    &--complete {
      background-color: #6FCF97;
    }
  }

  &__image {
    margin-bottom: 11px;
    min-height: 100px;
    background-color: transparent;

    img {
      width: 100%;
      height: auto;
      margin: 0;
    }
  }

  &__description {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #191419;
    margin-bottom: 12px;
  }

  &__points {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 8px;
  }

  &__deadline {
    background: #FF6F71;
    border-radius: 5px;
    padding: 2px 7px;
    display: inline-block;
    margin-bottom: 16px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.025em;
    color: #000000;
  }
}