$yellow: #ffff00;

.li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  height: 40px;
  padding-inline: 20px;
  border-radius: 4px;
  cursor: pointer;

  span {
    background-size: 15px;
    background-position: center;
  }

  h5 {
    margin-left: 20px;
    text-transform: uppercase;
  }

  &.active {
    color: $yellow;
    background-color: #333333e3;

    &:hover {
    color: $yellow;
    background-color: #333333e3;
    }
  }
}