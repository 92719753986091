@use "../../../../styles/base/size-screen" as screen;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #F0ECFC;
  font-family: 'Work Sans';

  .body {
    width: 100%;
    padding-left: 10px;

    h1 {
      font-size: 14px;
      font-weight: 700;
      margin: 0;

      @include screen.media-fluid(lg) {
        font-size: 1.1rem;
      }
    }

    h2 {
      font-size: 14px;
      font-weight: 400;
      margin: 0;

      @include screen.media-fluid(lg) {
        font-size: 1rem;
      }
    }

    h3 {
      margin: 0;
      font-size: 10px;
      font-weight: 400;
      color: #707372;

      @include screen.media-fluid(lg) {
        font-size: 1rem;
      }
    }

    h4 {
      margin: 0;
      margin-left: 5px;
      font-size: 10px;
      font-weight: 700;
      color: black;

      @include screen.media-fluid(lg) {
        font-size: 1rem;
      }
    }

    h5 {
      margin: 0;
      font-size: 10px;
      font-weight: 400;
    }

    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__label {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
    }

    &__description {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__bill {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 10px;

      h1 {
        white-space: nowrap;
      }

      div {
        padding-right: 10px;
      }
    }
  }
}
