.title {
  font-size: x-large;
  font-weight: bolder;
}

.box {
  background-color: white;
  padding: 10px;
  padding-inline: 30px;

  >li {
    list-style: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;

  > button {
    margin-top: 20px;
  }
}

.mb20 {
  margin-bottom: 20px;
}

.dialog__alert__container {
  padding-inline: 20px;

  > div {
    > button {
      margin-right: 0 !important;
    }
  }
}
