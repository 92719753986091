.maz-tu-info-bees-gallery-card {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  width: 284px;

  &__image {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 7px;
  }

  &__type {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 7px;

    &:before {
      content: "\A";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000000;
      display: inline-block;
      margin-right: 5px;
    }

    &--total {      
      &:before {
        background: linear-gradient(93.61deg, #FF9900 -3.91%, #FF6737 -3.9%, #FFC700 92.8%);
      }
    }
    &--premium {      
      &:before {
        background: linear-gradient(93.61deg, #037040 -3.91%, #009D58 -3.9%, #00E872 92.8%);
      }
    }
    &--coverage {      
      &:before {
        background: linear-gradient(89.63deg, #003E9B -39.62%, #4AF4FF 114.28%);
      }
    }
    &--marketpl {      
      &:before {
        background: linear-gradient(90deg, #000000 -44.82%, #81898A 47.5%, #000000 142.07%);
      }
    }
  }

  &__points {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    padding: 4px 8px;
    background: #E5E6E6;
    border-radius: 16px;
    display: inline-block;
    margin-bottom: 7px;

    &--completed {
      color: #FFFFFF;
      background: #6FCF97;
    }
  }

  &__expiration {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #FF6F71;
    padding: 4px 8px;
    background: #F7DADB;
    display: inline-block;
    border-radius: 16px;
    margin-bottom: 20px;
  }

  &__button {
    text-align: center;

    .maz-tu-info-bees-button {
      max-width: 200px;
    }
  }
  
}