.maz-tu-info-bees-title {
  padding: 0 39px;
  
  &__title {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    color: #000000;
  }
  &__subtitle {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }
}
