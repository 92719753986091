@use "../../../styles/base/size-screen" as screen;

.container {
  background-color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 10px;
  padding-bottom: 40px;

  width: map-get(screen.$grid-breakpoints, xs);

  @include screen.media-fluid(md) {
    width: map-get(screen.$grid-breakpoints, md);
  }

  h1 {
    font-size: 16px;
    margin-left: 10px;
  }

  p {
    margin-inline: 40px;
    font-size: 16px;

    h2{
      font-size: 16px;
      color: #546DD4;
    }

    h3{
      font-size: 16px;
      font-weight: 400;
    }

    &.text_center{
      text-align: center;
    }

    &.phone{
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        margin-left: 10px;
      }
    }
  }
}
