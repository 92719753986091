.icon {
  display: inline-block;
  width: var(--icon-size, 26px);
  height: var(--icon-size, 26px);
  background-repeat: no-repeat;
  background-size: auto var(--icon-size, 26px);

  /* List of icons */
  &-home {
    background-image: url('./../../../assets/icons/icon-home.svg');

    &-disabled {
      background-image: url('./../../../assets/icons/icon-home-disabled.svg');
    }
  }

  &-trade {
    background-image: url('./../../../assets/icons/icon-trade.svg');

    &-disabled {
      background-image: url('./../../../assets/icons/icon-trade-disabled.svg');
    }
  }

  &-logout {
    background-image: url('./../../../assets/icons/icon-logout.svg');

    &-disabled {
      background-image: url('./../../../assets/icons/icon-logout-disabled.svg');
    }

    &-white {
      background-image: url('./../../../assets/icons/icon-logout-white.svg');
    }
  }

  &-back-navigation {
    background-image: url('./../../../assets/icons/icon-back-navigation.svg');
  }

  &-info {
    &-white {
      background-image: url('./../../../assets/icons/icon-info-white.svg');
    }
  }

  &-success {
    &-white {
      background-image: url('./../../../assets/icons/icon-success-white.svg');
    }
  }

  &-warning {
    &-white {
      background-image: url('./../../../assets/icons/icon-warning-white.svg');
    }
  }

  &-error {
    &-white {
      background-image: url('./../../../assets/icons/icon-error-white.svg');
    }
  }

  &-close {
    &-white {
      background-image: url('./../../../assets/icons/icon-close-white.svg');
    }
    &-black {
      background-image: url('./../../../assets/icons/icon-close-black.svg');
    }
  }

  &-challenges {
    &-white {
      background-image: url('./../../../assets/icons/icon-challenges-white.svg');
    }

    &-yellow{
      background-image: url('./../../../assets/icons/icon-challenges-yellow.svg');
    }
  }

  &-airplay {
    &-white {
      background-image: url('./../../../assets/icons/icon-airplay-w.svg');
    }

    &-yellow{
      background-image: url('./../../../assets/icons/icon-airplay-y.svg');
    }
  }

  &-arrow{
    &-down {
      &-black {
        background-image: url('./../../../assets/icons/icon-arrow-down-black.svg');
      }

      &-yellow {
        background-image: url('./../../../assets/icons/icon-arrow-down-yellow.svg');
      }

      &-white {
        background-image: url('./../../../assets/icons/icon-arrow-down-white.svg');
      }
    }

    &-upper{
      &-white {
        background-image: url('./../../../assets/icons/icon-arrow-upper-white.svg');
      }

      &-yellow {
        background-image: url('./../../../assets/icons/icon-arrow-upper-yellow.svg');
      }

      &-black {
        background-image: url('./../../../assets/icons/icon-arrow-upper-black.svg');
      }
    }
  }

  &-edit {
    background-image: url('./../../../assets/icons/icon-edit.svg');
  }

  &-trash {
    background-image: url('./../../../assets/icons/icon-trash.svg');
  }

  &-exclamation {
    background-image: url('./../../../assets/icons/icon-exclamation.svg');
  }

  &-arrow {
    &-right {
      background-image: url('./../../../assets/icons/icon-arrow-right.svg');
    }
    &-left {
      background-image: url('./../../../assets/icons/icon-arrow-right.svg');
      transform: rotate(180deg);
    }
  }

  &-bank {
    background-image: url('./../../../assets/icons/icon-bank.svg');

    &-transfer {
      background-image: url('./../../../assets/icons/icon-bank-transfer.svg');
    }
    &-deposit {
      background-image: url('./../../../assets/icons/icon-bank-deposit.svg');
    }
  }

  &-virtual-account {
    background-image: url('./../../../assets/icons/icon-virtual-account.svg');

    &-footer {
      background-image: url('./../../../assets/icons/icon-virtual-account-footer.svg');

      &-disabled {
        background-image: url('./../../../assets/icons/icon-virtual-account-footer-disabled.svg');
      }
    }
  }

  &-account-state {
    background-image: url('./../../../assets/icons/icon-account-state-home.svg');

    &-footer {
      background-image: url('./../../../assets/icons/icon-bank.svg');

      &-disabled {
        background-image: url('./../../../assets/icons/icon-bank-disabled.svg');
      }
    }
  }

  &-bill {
    background-image: url('./../../../assets/icons/icon-bill.svg');
  }

  &-credit-card {
    background-image: url('./../../../assets/icons/icon-credit-card.svg');

    &-purple {
      background-image: url('./../../../assets/icons/icon-credit-card-purple.svg');
    }
  }

  &-chevron {
    &-sort {
      background-image: url('./../../../assets/icons/icon-chevron-sort.svg');
    }
  }

  &-filter {
    background-image: url('./../../../assets/icons/icon-filter.svg');

    &2{
      &-black{
        background-image: url('./../../../assets/icons/icon-filter2-black.svg');
      }
    }
  }

  &-sort {
    &-asc{
      background-image: url('./../../../assets/icons/icon-sort-asc.svg');
    }
  }

  &-support {
    background-image: url('./../../../assets/icons/icon-support.svg');

    &-disabled {
      background-image: url('./../../../assets/icons/icon-support-disabled.svg');
    }

    &-exclamation{
      background-image: url('./../../../assets/icons/icon-support-exclamation.svg');
    }

    &-globe{
      background-image: url('./../../../assets/icons/icon-support-globe.svg');
    }
  }

  &-calendar {
    background-image: url('./../../../assets/icons/icon-calendar.svg');
  }

  &-check {
    background-image: url('./../../../assets/icons/icon-check.svg');
  }

  &-candidate-menu {
    &-white {
      background-image: url('./../../../assets/icons/icon-candidate-menu-white.svg');
    }

    &-yellow {
      background-image: url('./../../../assets/icons/icon-candidate-menu-yellow.svg');
    }
  }

  &-circled-arrow-right {
    background-image: url('./../../../assets/icons/icon-circled-arrow-right.svg');
  }

  &-upload {
    background-image: url('./../../../assets/icons/icon-upload.svg');
  }

  &-search {
    background-image: url('./../../../assets/icons/icon-search.svg');
  }

  &-legal-representative {
    &-white{
      background-image: url('./../../../assets/icons/icon-legal-representative-white.svg');
    }

    &-yellow {
      background-image: url('./../../../assets/icons/icon-legal-representative.svg');
    }
  }

  &-download {
    background-image: url('./../../../assets/icons/icon-download.svg');
  }
}