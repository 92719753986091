.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.55);
}

.bg-light {
  background-color: #EAEAEA;
}

.navbar-toggler-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E");
}

.navbar-light .collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.navbar-toggler {
  background-color: initial;
  border: 1px solid transparent;
  border-radius: .25rem;
  font-size: 1.25rem;
  line-height: 1;
  padding: .25rem .75rem;
  transition: box-shadow .15s ease-in-out;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.55);
  font-size: 20px;
}

@media (max-width: 991px) {
  .bg-light {
      background-color: #fff;
  }
  .navbar-light .navbar-nav .nav-link:first-child {
    border-top: 1px solid #F0ECFC;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #000;
    padding: 1.2rem 1rem;
    border-bottom: 1px solid #F0ECFC;
    display: flex;
    align-items: center;
  }
}

  .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }


  .nav-link {
    color: #0d6efd;
    display: block;
    padding: .5rem 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  }

  .dropdown-toggle {
    white-space: nowrap;
  }

  .dropdown-toggle::after {
    border-bottom: 0;
    border-left: .3em solid transparent;
    border-right: .3em solid transparent;
    border-top: .3em solid;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
  }

  .dropdown-menu {
    background-clip: padding-box;
    background-color: #ccc;
    border-radius: .25rem;
    color: #212529;
    display: none;
    font-size: 1rem;
    list-style: none;
    margin: 0;
    margin-top: 0px;
    min-width: 10rem;
    padding: .5rem 0;
    position: absolute;
    text-align: left;
    z-index: 1000;
  }

  .navbar-nav .dropdown-menu {
    position: static;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: .125rem;
    top: 100%;
  }

  hr:not([size]) {
    height: 1px;
  }

  .dropdown-divider {
    border-top: 1px solid rgba(0,0,0,.15);
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
  }

  .dropdown-item:focus, .dropdown-item:hover {
    background-color: #e9ecef;
    color: #1e2125;
  }

  .dropdown-item {
    background-color: initial;
    border: 0;
    clear: both;
    color: #212529;
    display: block;
    font-weight: 400;
    padding: .25rem 1rem;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
  }


  .collapse:not(.show) {
    display: none;
  }

  @media (min-width: 992px) {

    .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header {
      display: none;
    }

    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      padding-left: .5rem;
      padding-right: .5rem;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }

    .navbar-expand-lg .navbar-nav .nav-link::after {
      content: "|";
      padding-left: 14px;
    }

    .navbar-expand-lg .navbar-nav .nav-link:last-child::after {
      content: "";
      padding: 0;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
  }

