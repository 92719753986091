@import "../../../styles/base/admin";

.container {
  .body {
    &__padding {
      padding: 20px 50px 0 50px;

      section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 20px;
        width: 100%;
      }
    }
  }
}

.searcher {
  display: flex;
  flex-direction: row;
  align-content: center;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding-left: 10px;
  height: 35px;

  input {
    border: 0;

    &:focus {
      outline: none;
    }
  }

  button {
    border: none;
    background-color: white;
  }
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
  width: 200px;
}