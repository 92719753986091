@import "../../../styles/base/admin";

.container {
  .body {
    &__padding {
      padding: 20px 50px 0 50px;

      section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 20px;
        width: 100%;
      }
    }
  }
}
