$gridTemplate: 2fr 1fr 0.5fr;

.container {
  max-height: calc(100vh - 200px);
  font-size: 16px;

  .header {
    display: grid;
    grid-template-columns: $gridTemplate;
    grid-gap: 10px;
    padding-block: 10px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      span {
        display: flex;
        align-items: center;
      }
    }

    h1 {
      font-size: 12px;
      font-weight: 700;
      color: black;
      margin: 0;
    }
  }

  .row {
    display: grid;
    grid-template-columns: $gridTemplate;
    grid-gap: 10px;
    padding-block: 10px;
  }

  .btn {
    cursor: pointer;
    border-radius: 4px;
    height: 40px;
    color: white;
    background-color: black;
  }
}