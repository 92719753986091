.maz-tu-info-bees-footer {
  border-top: 2px solid black;
  margin-top: 31px;
  padding-top: 24px;

  &__link {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    text-decoration-line: underline;
    margin-bottom: 8px;

    a { 
      color: #000000;
    }

    &:nth-child(3) {
      margin-bottom: 24px;
    }
  }

  &__text {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
  }

  &__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    &__pregnant {
      background-image: url('./../../../assets/icons/icon-footer-pregnant.svg');
      background-repeat: no-repeat;
      background-size: 16px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    &__driving {
      background-image: url('./../../../assets/icons/icon-footer-driving.svg');
      background-repeat: no-repeat;
      background-size: 16px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    &__18 {
      background-image: url('./../../../assets/icons/icon-footer-18.svg');
      background-repeat: no-repeat;
      background-size: 25px 12px;
      width: 25px;
      height: 12px;
    }
  }

  &__abinbev {
    width: 100%;
    height: 30px; 
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: #000000;
    position: relative;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
    }
  }
}
