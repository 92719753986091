$grid-breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@mixin media($media) {
  @if $media == xs {
    @media (max-width: map-get($grid-breakpoints, xs)) { @content; }
  }
  @else if $media == sm {
    @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) { @content; }
  }
  @else if $media == md {
    @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) { @content; }
  }
  @else if $media == lg {
    @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) { @content; }
  }
  @else if $media == xl {
    @media (min-width: map-get($grid-breakpoints, lg)) { @content; }
  }
}


@mixin media-fluid($media-start: xs, $media-end: xxl) {
  @if $media-end == xxl {
    @media (min-width: map-get($grid-breakpoints, $media-start)) { @content; }
  } @else {
    @media (min-width: map-get($grid-breakpoints, $media-start)) and (max-width: map-get($grid-breakpoints, $media-end)) { @content; }
  }
}