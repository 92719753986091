.historial {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 8px;
  padding-bottom: 215px;
  box-sizing: border-box;

  &__select {
    position: absolute;
    right: 8px;
    top: 19px;
    background: #FFFFFF;
    border: 1px solid #A7A8A9;
    box-sizing: border-box;
    border-radius: 4px;
    padding-right: 30px;
    padding-left: 16px;
    width: 177px;
    height: 38px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  .maz-tu-info-bees-title {
    margin-bottom: 8px;
  }
}