.maz-tu-info-bees-input {
  margin-bottom: var(--maz-tu-info-bees-input-margin-bottom, 0);
  position: relative;

  &__label {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.025em;
    color: #000000;
    width: 100%;
    display: inline-block;
    padding: 0 6px;
  
    &.error {
      color: #FF6F71;
    }

    &.success {
      color: #6FCF97;
    }
  
    &.helper-left {
      text-align: left;
    }
  
    &.helper-right {
      text-align: right;
    }
  }
  &__input {
    border: 0;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    height: 32px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 0 16px;
    box-sizing: border-box;
    outline: 0;
  
    &::placeholder {
      color: #A7A8A9;
    }
  
    &:disabled {
      background-color: #F0ECFC;
    }
  
    &.error {
      border: 1px solid #FF6F71;
    }

    &.success {
      border: 1px solid #6FCF97;
    }

    &.hide-phone {
      background-image: url('./../../../assets/icons/icon-hide-phone.svg');
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: right 15px top 10px;
      padding-right: 44px;
    }
    &.show-phone {
      background-image: url('./../../../assets/icons/icon-show-phone.svg');
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: right 15px center;
      padding-right: 44px;
    }
  }
  &__select {
    border: 0;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    height: 32px;
    overflow: hidden;
    background-image: url('../../../assets/icons/icon-chevron-select.svg');
    background-repeat: no-repeat;
    background-position: center right 22px;

    select {
      outline: 0;
      background: transparent;
      border: none;
      height: 32px;
      padding: 0 16px;
      width: 110%;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      box-sizing: border-box;
    }
  }
  &__helpers {
    display: flex;
    justify-content: space-between;
  }
  &__click-hide-phone {
    position: absolute;
    width: 44px;
    height: 32px;
    top: 18px;
    right: 0;
    z-index: 100;

    &:hover {
      cursor: pointer;
    }
  }
}
