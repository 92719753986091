.maz-tu-info-bees-card-brands {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  padding: 15px 30px;
  position: relative;
  box-sizing: border-box;
  padding-top: 46px;
  margin-bottom: 16px;

  &__title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    background-color: #A7A8A9;
    padding: 3px 30px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 21px;
    position: absolute;
    top: 0;
    left: 0;

    &--volumen {
      background-color: #FFFF00;
      color: #000000;
    }
    &--cerveza-premium {
      background-color: #000000;
      color: #ffffff;
    }
    &--cobertura {
      background-color: #A7A8A9;
      color: #ffffff;
    }
  }

  &__image {
    margin-bottom: 11px;
    min-height: 100px;
    background-color: transparent;

    img {
      width: 100%;
      height: auto;
      margin: 0;
    }
  } 

  &__goal {
    text-align: center;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;

    &__title {
      width: 100%;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      text-align: center;
      margin-bottom: 4px;
    }

    &__quantity {

      span {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: #000000;
      }

    }
  }

  &__deadline {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: center;
    margin-bottom: 40px;
  }

  &__points {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FF6F71;

    span {
      font-weight: 700;
    }
  }
}