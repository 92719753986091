.resultados {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 40px;

  .maz-tu-info-bees-title {
    margin-bottom: 51px;
  }

  &__card {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 0 8px;
    padding-top: 57px;
    padding-bottom: 18px;
    position: relative;
    margin: 0 8px;

    &__logo {
      width: 77px;
      height: 77px;
      background-size: 77px;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      position: absolute;
      left: 50%;
      margin-left: -38px;
      top: -27px;

      &.black {
        background-image: url('./../../assets/images/club/logo-club-black.svg');
      }
      &.black-zonas {
        background-image: url('./../../assets/images/club/logo-club-black-zonas.svg');
      }
      &.black-premium {
        background-image: url('./../../assets/images/club/logo-club-black-premium.svg');
      }
      &.mixto {
        background-image: url('./../../assets/images/club/logo-club-mixto.svg');
      }
      &.mixto-premium {
        background-image: url('./../../assets/images/club/logo-club-mixto-premium.svg');
      }
      &.b-black {
        background-image: url('./../../assets/images/club/B_BLACK.png');
      }
      &.b-max {
        background-image: url('./../../assets/images/club/B_MAX.png');
      }
    }

    &__title {
      text-align: center;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      color: #000000;
    }

    &__subtitle {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #000000;
      margin-bottom: 16px;
    }

    &__performance {
      margin-bottom: 21px;
      position: relative;

      &__hex {
        height: 175px;
        width: 200px;
        background-image: url('./../../assets/images/hex-background-results.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        z-index: 10;

        &__line {
          padding-left: 60px;
          padding-right: 60px;
          height: 59px;
          padding-top: 14px;
          width: 100%;
          box-sizing: border-box;
          color: #000000;
          
          &:nth-child(2) {
            padding-top: 6px;
            color: #ffffff;
          }

          &:nth-child(3) {
            padding-top: 0px;
            margin-top: -1px;
          }

          &__subtitle {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.025em;
            text-align: left;
          }

          &__points {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 20px;
            text-align: left;
          }

          &__label {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.025em;
            text-align: right;
          }
        }
      }

      &__bars {
        position: absolute;
        top: 12px;
        right: 0;
        width: calc(100% - 100px);
        z-index: 5;

        &__bar {
          height: 45px;
          box-shadow: inset 0px 3px 3px rgb(0 0 0 / 20%);
          display: flex;
          align-items: center;
          justify-content: end;
          width: 100%;
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          padding-right: 7px;
          box-sizing: border-box;
          margin-bottom: 8px;

          &.error {
            background-color: #FF6F71;
          }

          &.success {
            background-color: #6FCF97;
          }

          &.standby {
            background-color: #B6B6B6;
          }
        }
      }

    }

    &__info {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #000000;
      text-align: right;
    }

  }

}