.contrato-firma {

  &__title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    color: #000000;
    margin: 8px 0 20px 0;
  }

  &__message {
    background-color: #ffffff;
    padding: 16px;
    margin: 15px 0;
  }

  &__modal {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 60px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 10;

    @media (min-width: 600px) {
      display: none;
    }

    &__icon {
      background-image: url('./../assets/icon-rotate.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80px;
      width: 80px;
      height: 80px;
      margin: 0 auto;
      margin-top: 180px;
      margin-bottom: 32px;
    }

    &__message {
      background: #FFFFFF;
      box-shadow: 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%), 0px 3px 5px rgb(0 0 0 / 20%);
      border-radius: 4px;
      margin: 0 15px;
      box-sizing: border-box;
      padding: 16px;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #000000;

      &__title {
        font-weight: 700;
        margin-bottom: 18px;
      }

      &__text {
        margin-bottom: 18px;
      }

      &__button {
        text-align: right;

        button {
          border: none;
          background: transparent;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          letter-spacing: 0.0125em;
          text-transform: uppercase;
          color: #2F80ED;
        }
      }
    }
  }

  &__iframe {
    background: rgba(0,0,0,.6) 50% no-repeat;
    background-size: 50px;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    
    &--close {
      display: none;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}