@import "../../../../styles/base/admin";

$gridTemplate: 2fr 2fr 1fr 1fr 1fr 0.5fr;

.container {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  font-size: 16px;

  .header {
    display: grid;
    grid-template-columns: $gridTemplate;
    grid-gap: 10px;
    padding: 10px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      span {
        display: flex;
        align-items: center;
      }
    }

    h1 {
      font-size: 12px;
      font-weight: 700;
      color: black;
      margin: 0;
    }
  }

  .row {
    display: grid;
    grid-template-columns: $gridTemplate;
    grid-gap: 10px;
    padding-block: 10px;

    > div {
      box-shadow: inset 0px -1px 0px #EAEAEA;
    }
  }

  .center {
    text-align: center;
  }
}
