.container {
  background-image: url('./assets/banner.jpg');
  background-size: cover;
  min-width: 400px;
  max-width: 400px;
  min-height: 200px;
  max-height: 200px;
  margin: auto;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  >.points {
    font-weight: 800;
    top: 24px;
    left: 35px;
    color: black;

    &__digits {
      &--1 {
        font-size: 32px;
      }

      &--2 {
        font-size: 30px;
      }

      &--3 {
        font-size: 28px;
      }

      &--4 {
        font-size: 26px;
      }

      &--5 {
        font-size: 24px;
      }
    }
  }
}