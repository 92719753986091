.details{
  padding: 10px;

  section {
    display: flex;
    flex-direction: row;

    h3 {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #707372;
    }

    h4 {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      margin-left: 10px;
    }
  }
}

.row {
  border-bottom: 1px solid #F0ECFC;
  padding-block: 10px;

  span {
    display: flex;
    flex-direction: row;
  }

  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;

    &.green{
      color:#296117;
      font-weight: 500;
    }
  }

  &__quantities {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      :nth-child(2) {
        text-decoration: line-through;
      }
    }
  }
}

.totals{
  border-bottom: 1px solid #F0ECFC;

  p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h1 {
      margin: 0;
      font-size: 22px;
      font-weight: 500;
    }

    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.infoClient {
  h1 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-top: 10px;
  }

  h3 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
  }
}
