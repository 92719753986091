.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100% - 200px);

  .form {
    max-width: 600px;
    padding-left: 20px;
    padding-bottom: 150px;
    color: black;

    h1 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 30px;
      color: black;
    }

    h2 {
      font-size: 14px;
      font-weight: 700;
    }

    h3 {
      color: red;
      font-size: 14px;
      font-weight: 700;
    }

    .thumbnail {
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      width: 400px;
      height: 220px;

      .icon{
        float: right;
        margin: 10px;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      }
    }

    &__control{
      input {
        height: 30px;
        width: 100%;
        border: 1px solid #A7A8A9;
        border-radius: 4px;
        padding-left: 20px;

        &:disabled {
          background-color: #F0ECFC;
        }
      }

      &__text_editor {
        > div {
          border: 1px solid #A7A8A9;
          border-radius: 4px;
          padding: 10px;
        }
      }
    }
  }

  .footer {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: white;
    width: calc(100% - 245px);
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 2px solid #F0ECFC;

    button {
      background-color: white;
      height: 40px;
      border-radius: 4px;
      width: 200px;
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

      &.save {
        color: white;
        background-color: black;
        margin-right: 40px;

        &:disabled {
          background-color: #F0ECFC;
          color: #A7A8A9;
          border: none;
        }
      }
    }
  }
}
