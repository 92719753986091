.maz-tu-info-bees-columns-info {
  background: #F0ECFC;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
  border-radius: 4px;
  padding: 7px 9px;
  display: table;
  width: 100%;
  box-sizing: border-box;

  &__column {
    background-image: url('./../../../assets/images/border-left-columns.svg');
    background-repeat: no-repeat;
    background-position: right center;
    display: table-cell;

    &:last-child {
      background: none;

      .maz-tu-info-bees-columns-info__column__content {
        font-weight: 700;
      }
    }

    &__title {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #000000;
      padding: 0 8px;
    }

    &__content {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #000000;
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;

      &--bold {
        font-weight: 700;
      }
    }

  }
}