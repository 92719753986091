.MuiSlider-root {
  color: yellow !important;
}

.MuiSlider-rail {
  color: black !important;
  opacity: 1 !important;
}

.MuiSlider-thumb {
  color: #F3F3F3;
}