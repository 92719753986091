.document-upload {
  height: 100%;

  .maz-tu-info-bees-card-upload {
    margin-bottom: 16px;
  }

  &__title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    color: #000000;
    margin: 8px 0 20px 0;
  }

  &__next {
    position: absolute;
    bottom: 32px;
    left: 25px;
    right: 25px;
  }

  &__message {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: -0.025em;
    color: #000000;
    margin-top: 4px;

    &--error {
      color: #FF6F71;
    }
  }
  
}