.editing {
  cursor: pointer;
}

.row {
  &--0 {
    background: #eaeaea;
  }
}

.btn {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  &--save {
    background-image: url('../../../../../pages/ProgramaMercado/assets/icon-save.svg');
  }

  &--cancel {
    background-image: url('../../../../../assets/icons/icon-close-black.svg');
    width: 15px;
    height: 15px;
  }
}