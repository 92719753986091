$greyC4: #c4c4c4;

.band {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: white;
  border: none;
  height: 40px;
  width: 100%;
  padding-inline: 10px;
  margin: 0;
  font-size: 20px;
  font-weight: 700;

  span {
    background-size: 15px;
    background-position: center;
  }

  &--orange {
    background: linear-gradient(93.61deg, #FF9900 -3.91%, #FF6737 -3.9%, #FFC700 92.8%);
  }

  &--yellow {
    background: #FFF000;
    color: black;
  }
}
