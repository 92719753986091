.maz-tu-info-bees-history-table {
  width: 100%;
  background-color: #ffffff;

  &__row {
    padding: 14px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F0ECFC;

    &__info {
      &__date {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.025em;
        color: #a7a8a9;
      }

      &__text {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }
    }

    &__value {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: #6fcf97;
    }
  }

  &__no-data {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 14px 12px;
  }
}
