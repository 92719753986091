@import "../../../../styles/base/admin";

.searcher {
  display: flex;
  flex-direction: row;
  align-content: center;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding-left: 10px;
  height: 35px;

  input {
    border: 0;

    :focus {
      outline: none;
    }
  }

  button {
    border: none;
    background-color: white;
  }
}