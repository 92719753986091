@use "../../../styles/base/size-screen" as screen;
$widthMobile: map-get(screen.$grid-breakpoints, xs);

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 10px;
  padding-block: 20px;
  height: 477px;

  &__image {
    height: 350px;
  }

  .indicators {
    padding-bottom: 0;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      max-width: 200px;
    }
  }

  p {
    padding-inline: 5px;
    min-height: 60px;
    margin: 5px auto;

    h2 {
      font-size: 14px;
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  bottom: 60px;
  padding: 10px;
  background-color: #F0ECFC;



  button {
    height: 40px;
    width: 152px;
    border-radius: 4px;
    text-transform: uppercase;
    border: none;
    margin-left: 10px;
    margin-right: 10px;

  @include screen.media-fluid(md) {
    width: 160px;
  }

  @include screen.media-fluid(md) {
    width: 160px;
  }

    &.btn{
      &__white {
        background-color: white;
        color: black;
        border: 1px solid black;
        visibility: initial;

        &__hide {
          visibility: hidden;
        }
      }

      &__black {
        background-color: black;
        color: white;
      }
    }
  }
}
