$greyC4: #c4c4c4;

.container {
    display: flex;
    background-color: white;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

    h1 {
      font-size: 32px;
      font-weight: 700;
      padding-left: 35px;
    }

    h5 {
      display: flex;
      flex-direction: row;
      padding-right: 50px;
      font-size: 16px;
      font-weight: 400;
    }

    div {
      display: flex;
      flex-direction: row;
      padding-right: 50px;
    }

    &__user_info {
      align-items: center;

      span {
        border: 1px solid $greyC4;
        border-radius: 50%;
        background: $greyC4;
        height: 45px;
        width: 45px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 700;
      }
    }

    &__back {
      padding-left: 10px;
    }
}