.maz-tu-info-bees-card-upload {
  padding: 15px 30px;
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  &__label {
    display: flex;
    align-items: center;
    
    &__status {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 10px;
      background-image: url('./assets/loader.gif');

      &--pending {
        background-image: url('./assets/icon-upload-pending.svg');
      }

      &--validating {
        background-image: url('./assets/icon-upload-validating.svg');
      }

      &--selected {
        background-image: url('./assets/icon-upload-validating.svg');
      }

      &--success {
        background-image: url('./assets/icon-upload-success.svg');
      }

      &--error {
        background-image: url('./assets/icon-upload-error.svg');
      }

    }

    &__text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }

  }

  &__actions {
    display: flex;
    align-items: center;

    &__upload-document {
      border: 0;
      background-color: transparent;
      background-image: url('./assets/icon-upload-document.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30px 26px;
      width: 30px;
      height: 26px;
    }

    &__delete-document {
      border: 0;
      background-color: transparent;
      background-image: url('./assets/icon-upload-delete.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px 20px;
      width: 20px;
      height: 20px;
      margin-right: 30px;
    }

    &__edit-document {
      border: 0;
      background-color: transparent;
      background-image: url('./assets/icon-upload-edit.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      width: 20px;
      height: 20px;
    }

  } 
}