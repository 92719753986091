/* You can add global styles to this file, and also import other style files */
// <@import> will be deprecated in favor of <@use> and @forward, and support will be dropped by October 2022 at the latest.
// @use "~styles/base/_base";
// @use "~styles/base/_colors";
// @use "~styles/base/_size-screen";
// @use "~styles/buttons/_buttons";
// @use "~styles/variables/_variables";

// CONFIGURANDO PARA QUE LOS ESTILOS SE IMPORTEN GLOBALMENTE

body, html {
  height: 100%;
}

*, :after, :before {
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
}
