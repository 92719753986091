.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 220px;
  width: 560px;

  h1 {
    font-size: 20px;
    margin: 0;
  }

  &__footer {
    button {
      background-color: white;
      height: 40px;
      border-radius: 4px;
      width: 200px;
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

      &.save {
        color: white;
        background-color: black;
        margin-right: 40px;
      }
    }
  }
}
