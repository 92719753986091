.container {
  display: flex;
  flex-direction: column;
  padding-inline: 20px;

  h1 {
    font-size: 24px;
    font-weight: 700;
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin: 12px auto;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  h4 {
    font-size: 12px;
    font-weight: 400;
    color: #707372;
    margin: 0;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #F0ECFC;

    button {
      margin-top: 10px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: none;
      background-color: #F0ECFC;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  .slider {
    padding-bottom: 10px;
    border-bottom: 1px solid #F0ECFC;

    &__titles {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        margin: 0;
        margin-top: -10px;
      }

      :nth-child(2) {
        text-align: right;
      }
    }
  }

  .sucursal {
    input {
      border: 0px;
      background: rgba(118, 118, 128, 0.12);
      border-radius: 10px;
      width: 100%;
      height: 36px;
      padding-left: 40px;

      ~ label {
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.6;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%233c3c43'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .dates {
    border-bottom: 1px solid #f0ecfc;
    padding-bottom: 10px;

    section {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      h5 {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
      }

      > div {
        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-inline: 10px;
          border: 1px solid black;
          border-radius: 4px;

          h1 {
            margin: 0;
            margin-right: 5px;
            font-size: 16px;
            font-weight: 400;
            color: #A7A8A9;
            padding: 5px;
          }
        }
      }
    }
  }

  .calendar {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &.hidden {
      visibility: hidden;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;

    button {
      background-color: white;
      height: 40px;
      width: 150px;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      border: 1px solid black;

      &.btn_black {
        color: white;
        background-color: black;
      }
    }
  }
}

.results-list {
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0ECFC;
    padding: 10px 0;

    input {
      width: 24px !important;
      height: 24px !important;
    }
  }
}

