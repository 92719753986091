.container {
  display: flex;
  flex-direction: column;
  width: 280px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 52px;
    border-bottom: 1px solid #F0ECFC;

    h1 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
  }
}