@import "../../../styles/base/admin";

.container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  &__body {
    height: calc(100% - 65px);
    overflow: auto;

    background-color: white;
    margin: 0;
    margin-top: 2px;

    &__padding {
      padding: 10px 50px 0 50px;
    }
  }
}