.thank-you {

  &__container {
    background: #FFFF00;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top: 78px;
    margin-bottom: 57px;
    padding-bottom: 77px;

    &__logo {
      background-image: url('./../assets/icon-club-black.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 94px 113px;
      width: 94px;
      height: 113px;
      position: relative;
      width: 100%;
      top: -56px;
    }

    &__title {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
      color: #000000;
      margin-bottom: 52px;
    }

    &__text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin: 0 45px;
    }
  }
}