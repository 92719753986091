
.thumbnail {
  position: relative;

  img {
    max-height: 220px;
    max-width: 420px;
  }

  .icon{
    float: right;
    margin: 10px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}