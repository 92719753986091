.container {
  h1 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-block: 10px;
  }

  &__bills{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
  }

  .indicators{
    margin-top: 20px;
  }
}

.card {
  border: 1px solid #A7A8A9;
  min-width: 320px;
  width: 320px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 4px 10px rgba(25, 1, 52, 0.16);

  &.active {
    border: 1px solid #FF9900;

    .check{
      visibility: inherit;
    }
  }

  h1 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #707372;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    color: black;
  }

  span {
    display: flex;
    flex-direction: row;

    h2 {
      margin-left: 5px;
    }
  }

  &__body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      margin: 10px;
    }

    .check{
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: black;
    border-radius: 0px 0px 4px 4px;

    h1 {
      color: white;
      font-size: 16px;
      font-weight: 700;
      margin: 10px;
    }
  }
}

.table {
  > div {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }

  &__header {
    background-color: #F0ECFC;
    padding-inline: 10px;
  }

  &__row {
    border-bottom: 1px solid #F0ECFC;
    padding-block: 10px;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }

    h2 {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: #000;
    }

    h3 {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: #000;
    }

    h4 {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: #A7A8A9;
      white-space: nowrap;
    }
  }
}

.button {
  color: #fff;
  font-weight: 500;
  padding: 10px;
  background: #000000;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.totals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #F0ECFC;

  h1 {
    font-size: 12px;
    font-weight: 700;
    margin: 16px;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    margin: 16px;
  }
}
