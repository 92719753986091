.maz-tu-info-bees-resume {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  /* background-image: url(/static/media/hex-background2.463c6dc….svg); */
  /*background-position-x: calc(50% + 18px);*/
  /* background-position-y: center; */
  /*background-repeat: no-repeat;
  background-size: 430px;*/
  /* display: flex; */
  /* font-size: 10px; */
  /* font-weight: 700; */
  /* height: 100px; */
  /*max-width: 353px;*/
  height: 281px;
  /*
  width: 470px;
  min-width: 470px;
  margin: auto;*/
  /*
  background-position: center right -64px;
  background-size: auto 440px;*/
  position: relative;



  &__nivel {
    /*position: absolute;*/
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 64px;
    color: #000000;
    margin-left: 8px;
  }

  &__info {
    height: 100%;
    width: 380px;
    min-width: 380px;
    /*padding-left: 39px;*/

    background-image: url("./../../../assets/images/hex-background2.svg");
    background-repeat: no-repeat;
    background-size: 380px;
    background-position-x: calc(50% + 10px);
    background-position-y: center;

    &__row {
      height: 70px;
      padding-top: 10px;
      box-sizing: border-box;

      &__cell {
        height: 100%;
        text-align: right;
        /* padding-left: 75px; */
        /* padding-top: 18px; */
        box-sizing: border-box;
        width: 50%;
        float: left;

        .maz-tu-info-bees-resume__info__row__cell__title {
          margin-top: -5px;
          overflow: hidden;
          max-height: 12px;
        }

        &--right {
          padding-right: 65px;
          padding-left: 8px;
          border-left: 1px solid grey;

          .maz-tu-info-bees-resume__info__row__cell__value {
            text-align: left;
            margin-top: 8px;

            &.font-small {
              &--1 { /* 5-digit number */
                font-size: 26px;
              }
              &--2 { /* 6-digit number */
                font-size: 22px;
              }
              &--3 { /* 7-digit number */
                font-size: 20px;
              }
            }
          }

          .maz-tu-info-bees-resume__info__row__cell__description {
            text-align: left;
            padding-left: 15px;
            padding-top: 3px;
          }
        }

        &__title {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: -0.025em;
          color: #000000;
          margin-right: 10px;

          &.font-small-1 {
            font-size: 8px;
          }
        }

        &__value {
          font-family: "Barlow";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 30px;
          color: #000000;
          letter-spacing: -2px;
          margin-right: 20px;
        }

        &__description {
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: -0.025em;
          color: #000000;
          letter-spacing: -1px;
          margin-right: 20px;
        }
      }
    }

    &__row--2 {
      padding: 0;
      padding-top: 12px;
      /* height: 102px; */

      .maz-tu-info-bees-resume__info__row__cell {
        /* padding-top: 30px; */

        .maz-tu-info-bees-resume__info__row__cell__title {
          color: #ffffff;
          margin-top: -5px;
          position: relative;
          left: -5px;
        }

        &--right {
          /* padding-top: 29px; */
          padding-left: 8px;
          padding-right: 19px;

          .maz-tu-info-bees-resume__info__row__cell__value {
            color: #ffffff;
            text-align: left;
          }

          .maz-tu-info-bees-resume__info__row__cell__description {
            color: #ffffff;
            text-align: left;
            padding-left: 15px;
          }
        }

        .maz-tu-info-bees-resume__info__row__cell__value {
          color: #ffffff;
        }

        .maz-tu-info-bees-resume__info__row__cell__description {
          color: #ffffff;
        }
      }
    }

    &__row--3 {
      padding-bottom: 10px;
      padding-top: 0;

      .maz-tu-info-bees-resume__info__row__cell {
        padding-top: 9px;

        .maz-tu-info-bees-resume__info__row__cell__title {
          margin-top: -3px;
        }

        &--right {
          padding-top: 9px;
          padding-left: 8px;
          padding-right: 52px;

          .maz-tu-info-bees-resume__info__row__cell__value {
            text-align: left;
          }

          .maz-tu-info-bees-resume__info__row__cell__description {
            text-align: left;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
