$bees-grey: #F0ECFC;

.container {
    padding-top: 50px

/*     &::-webkit-scrollbar{
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
   /*  scrollbar-width: none;  */ /* Firefox */
}

h2 {
    font-size: 20px;
    font-weight: bold;
    margin-left: 16px;
  }

.card {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);

  & h1 {
    font-size: 24px;
    font-weight: 700;
    padding: 8px;
    //border-bottom: 1px solid $bees-grey;
    margin-bottom: 0;
  }

  &__action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 51px;
    border-bottom: 1px solid $bees-grey;
    padding-right: 10px;
    cursor: pointer;

    & div {
      display: flex;
      align-items: center;
      padding-left: 20px;

      & span {
        padding-right: 10px;
      }
    }
  }

  &__row {
    padding-left: 16px;
    padding-top: 20px;
    font-size: 16px;

    & h5 {
      font-size: 12px;
      color: #707372;
      font-weight: 400;
      margin: 0;
    }

    &--black{
      color: white;
      background-color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70px;
      margin-top: 10px;

      & h4 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }

      & div {
        font-size: 20px;
        font-weight: 700;
        padding-top: 10px;
      }
    }

    &--yellow{
      color: black;
      background-color: yellow;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70px;

      & h4 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }

      & div {
        font-size: 20px;
        font-weight: 700;
        padding-top: 10px;
      }
    }
  }
}

.card_payments {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
  padding: 10px;
  text-align: center;

  h2 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
  }

  button {
    background: linear-gradient(46.68deg, #E2BDFF 0%, #F7EDFF 97.23%);
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 35px;
  }
}
