.resumen {

  .maz-tu-info-bees-title {
    margin: 8px 0;
  }

  .maz-tu-info-bees-card-info {
    margin-bottom: 20px;
  }

  &__datos {

    &__title {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: #000000;
      margin-bottom: 24px;
    }

    &__row {
      background: #ffffff;
      border-bottom: 1px solid #F0ECFC;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 4px 12px;
      min-height: 50px;

      &__label {

        .maz-tu-info-bees-input {
          width: 200px;

          &__label {
            padding: 0;
          }

          &__input {
            padding: 0;
            border-bottom: 1px solid #666666;
            border-radius: 0;
          }
        }

        &__subtitle {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: -0.025em;
          color: #A7A8A9;
        }

        &__text {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
        }
      }

      &__actions {

        &__button {
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;
          width: 20px;
          height: 20px;
          border: none;
          background-color: transparent;

          &--success {
            background-image: url('./../assets/icon-resumen-success.svg');
          }

          &--error {
            background-image: url('./../../../components/maz-tu-info-bees/maz-tu-info-bees-card-upload/assets/icon-upload-error.svg');
          }

          &--edit {
            background-image: url('./../assets/icon-resumen-edit.svg');
            margin-right: 27px;
          }

          &--save {
            background-image: url('./../assets/icon-save.svg');
            margin-right: 27px;
          }

          &--cancel {
            background-image: url('../../../assets/icons/icon-close-black.svg');
            margin-right: 27px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }

  }

  &__buttons {
    margin: 24px 0;

    button {
      margin-bottom: 13px;
    }

  }
}