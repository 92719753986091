$yellow: #ffff00;

.container {
  display: grid;
  grid-template-columns: 1fr;
  align-content: space-between;

  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  background-color: black;

  width: auto;
  height: 100%;
  padding: 10px;
  margin: 0;

  &__bees_icon {
    background-size: 110px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../../assets/images/bees-logo-admin.svg");
    height: 120px;
  }

  &__footer {
    padding-bottom: 30px;

    span {
      background-size: 10px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    padding-right: 5px;
    margin: 0;
    overflow-y: auto;
    max-height: calc(100vh - 200px);

    &::-webkit-scrollbar {
      margin-left: 5px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: yellow;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 0, 0.241);
    }
  }
}