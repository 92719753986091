$bees-grey: #F0ECFC;

.container {
  max-width: 850px;
  padding-top: 50px;
  padding-bottom: 100px;
  margin: auto;
  height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.xto__dump {
  height: 80px;
}

.hideOnMobile {
  display: none;

  @media screen and (min-width: 600px) {
    display: block;
  }
}
