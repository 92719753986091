.captura-info {

  .maz-tu-info-bees-card-info {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .maz-tu-info-bees-button {
    margin-bottom: 24px;
  }

  .maz-tu-info-bees-input {
    margin-bottom: 17px;
  }

  &__form {
    padding: 0 17px;
    box-sizing: border-box;
  }

  &__title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }

  &__notice {
    background: #FF6F71;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
    border-radius: 4px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #F0ECFC;
    padding: 20px 17px;
    margin: 37px 0;
  }
  
}
