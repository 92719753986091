.containerAppInApp {
  position: fixed;
  top: 0px;
  bottom:0px;
  left: 0px;
  right: 0px;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  flex-direction: column;
  z-index: 15;
}

.blockInApp {
  position: fixed;
  top: 0px;
  bottom:0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  flex-direction: column;
  z-index: 10;
  backdrop-filter: blur(5px);
}

.brochuereInApp {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 8px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  background-color: white;
  border-top-right-radius: 10px;
  height: 95%;
  padding: 10px;
  z-index: 14;
  display: flex;
  flex-direction: column;
  .maz-tu-info-bees-title {
    margin-bottom: 34px;
    padding: 0 !important;
    margin-top: 19px;
  }
  .maz-tu-info-bees-button {
    margin-bottom: 24px;
  }
}

.appInAppHeader {
  width: 100%;
  display: flex;
  align-items: center;
  flex: .2
}

.appInAppLeft {
  display: flex;
  flex: .8;
}

.appInAppRight {
  display: flex;
  flex: .2;
  justify-content: flex-end;
}


.closeButtonContainer {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: #F0ECFC;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;-webkit-box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
  -moz-box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
  box-shadow: 9px 9px 30px -15px rgba(0,0,0,0.61);
}


.elementRow {
  padding-top: 4px;
  padding-bottom: 4px;
}

.appInAppTopNotif {
  width: 100%;
  padding: 5px;
  background-color: #FFFF00;
}

.notifTittle {
  font-size: 12px;
  font-weight: 700;
}

.appImagePreviewInApp {
  width: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
}

.appInAppBody {
 width: 100%;
 display: flex;
 flex: .6;
 flex-direction: column;
}

.appInAppPreviewFooter {
  width: 100%;
  background-color: #F0ECFC;
  padding: 10px;
  display: flex;
  flex: .2;
  align-items: center;
}

.inAppLeftPreviewInApp {
  display: flex;
  flex: .2
}

.inAppRigthPreviewInApp {
  display: flex;
  flex: .8
}

.dialogGlowInAppPreview {
  width: 100%;
  padding: 5px;
  background-color: white;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.triangleDialog {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 30px 10px 0;
  border-color: transparent white transparent transparent;
  position: absolute; /* centered first regardless of width*/
  margin-left: -30px; 
}

.appInAppImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
