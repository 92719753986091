.container {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  label {
    width: 350px;
    box-sizing: border-box;
  }
}
