.container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;


  .body {
    height: calc(100% - 65px);
    overflow: auto;

    background-color: white;
    margin: 0;
    margin-top: 2px;

    &__padding {
      padding: 50px 50px 0 50px;
    }
  }
}

.btn {
  cursor: pointer;
  border-radius: 4px;
  height: 40px;

  &--black{
    color: white;
    background-color: black;
  }
}

.ml10 {
  margin-left: 10px;
}

.bold {
  font-weight: bold;
}

.success {
  color: green;
}

.fail{
  color: red;
}
