.login {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  background-color: #F0ECFC;

  &__logo {
    background-image: url('./../../../../assets/images/bees-logo-login.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 116px;
    height: 100px;
    margin: 40px auto;
  }

  &__button {
    width: 100%;
    text-align: center;
    --maz-tu-info-bees-button-width: 200px;
  }
}
