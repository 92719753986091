.dashboard {
  width: 100%;
  padding: 16px;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 17px;
    margin-bottom: 16px;

    &__club {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #000000;

      img {
        max-width: 48px;
        position: relative;
        left: -10px;
      }
    }

    &__level {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
  }

  &__gallery {
    margin-bottom: 32px;
    padding: 24px 16px;
    box-sizing: border-box;
    max-width: none;
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    background-color: #ffffff;
  }

  &__challenges {
    margin-bottom: 16px;
    
    a {
      text-decoration-line: none;
    }
  }
}
