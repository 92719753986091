.facturacion {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 8px;
  box-sizing: border-box;

  &__resume {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 16px;

    &__title {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #000000;
    }

    &__amount {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      text-align: center;
      color: #000000;
      margin-bottom: 12px;
    }

    &__progress {
      background: #F3F3F3;
      box-shadow: inset 0px 3px 3px rgb(0 0 0 / 20%);
      border-radius: 40px;
      display: table;
      width: 100%;
      padding: 4px;
      margin-bottom: 4px;
      position: relative;
      overflow: hidden;

      &__bar {
        position: absolute;
        height: 100%;
        background: linear-gradient(93.61deg, #FF9900 -3.91%, #FF6737 -3.9%, #FFC700 92.8%);
        box-shadow: inset 0 3px 3px rgb(0 0 0 / 20%);
        z-index: 1;
        top: 0;
        left: 0;
      }

      &__section {
        display: table-cell;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: -0.025em;
        color: #000000;
        border-right: 1px solid #A7A8A9;
        z-index: 2;
        position: relative;

        &:last-child {
          border-right: none;
        }
      }
    }

    &__labels {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      &__left, &__right {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: #000000;
      }
    }

    &__description {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      padding-bottom: 21px;
      border-bottom: 1px solid #A7A8A9;
      margin-bottom: 8px;
    }

    &__award {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #000000;

      span {
        background: #FFFF00;
        display: inline-block;
        margin-left: 8px;
        padding: 2px 4px;
        font-weight: 700;
      }
    }
  }

  &__levels {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 12px 20px;

    &__title {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #000000; 
      margin-bottom: 13px;
    }

    &__resume {
      width: 260px;
      height: 260px;
      background-image: url('./assets/background_billing.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 260px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;

      &__column {
        height: 100%;
        width: 50%;

        &__row {
          box-sizing: border-box;
          padding-left: 50px;
          padding-right: 6px;

          &:nth-child(1) {
            padding-top: 30px;
          }

          &:nth-child(2) {
            padding-top: 21px;
          }

          &:nth-child(3) {
            padding-top: 16px;
          }

          &__title {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            text-align: right;
          }

          &__amount {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            color: #000000;
            text-align: right;
            margin-top: -5px;
          }

          &__label {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            text-align: right;
            margin-top: -5px;
          }
        }

        &:nth-child(2) {

          .facturacion__levels__resume__column__row {
            box-sizing: border-box;
            padding-right: 50px;
            padding-left: 6px;

            &__title, &__amount, &__label {
              text-align: left;
            }

            &__amount {
              font-family: 'Barlow';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 140%;
              color: #000000;
              margin-top: 0px;
            }

            &:nth-child(1) {
              padding-top: 30px;
            }
  
            &:nth-child(2) {
              padding-top: 22px;
            }
  
            &:nth-child(3) {
              padding-top: 17px;
            }
          }

        }

      }
    }
  }
}