.desafios {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 8px;
  padding-bottom: 120px;
  box-sizing: border-box;

  &__logo-nivel {
    width: 40px;
    height: 40px;
    background-size: 40px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    top: 29px;
    right: 29px;

    &.black {
      background-image: url('./../../assets/images/club/logo-club-black.svg');
    }
    &.black-zonas {
      background-image: url('./../../assets/images/club/logo-club-black-zonas.svg');
    }
    &.black-premium {
      background-image: url('./../../assets/images/club/logo-club-black-premium.svg');
    }
    &.mixto {
      background-image: url('./../../assets/images/club/logo-club-mixto.svg');
    }
    &.mixto-premium {
      background-image: url('./../../assets/images/club/logo-club-mixto-premium.svg');
    }
    &.b-black {
      background-image: url('./../../assets/images/club/B_BLACK.png');
    }
    &.b-max {
      background-image: url('./../../assets/images/club/B_MAX.png');
    }
  }

  &__title {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    color: #000000;
    padding: 0 39px;
  }

  &__label {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin: 0 39px;
    padding: 4px 6px;
    max-width: 191px;
    margin-bottom: 8px;

    &--orange {
      background: linear-gradient(
        93.61deg,
        #ff9900 -3.91%,
        #ff6737 -3.9%,
        #ffc700 92.8%
      );
    }

    &--blue {
      background: linear-gradient(89.63deg, #003e9b -39.62%, #4af4ff 114.28%);
    }

    &--green {
      background: linear-gradient(
        93.61deg,
        #037040 -3.91%,
        #009d58 -3.9%,
        #00e872 92.8%
      );
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 16px;
  }

  .maz-tu-info-bees-button {
    margin-bottom: 16px;
  }
}
