.maz-tu-info-bees-toast {
  background: #56CCF2;
  border-radius: 5px;
  padding: 14px 18px;
  box-sizing: border-box;
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
  display: none;

  &.open {
    display: flex;
  }

  &--info {
    background: #56CCF2;
  }

  &--success {
    background: #6FCF97;
  }

  &--warning {
    background: #FFB042;
  }

  &--error {
    background: #FF6F71;
  }

  &__message {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    padding: 0 15px;
    text-align: center;
  }
}