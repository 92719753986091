@use "../../../styles/base/size-screen" as screen;

.maz-tu-info-bees-modal-action {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  box-sizing: border-box;
  padding: 0 15px;
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  &__card {
    background: #FFFFFF;
    box-shadow: 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%), 0px 3px 5px rgb(0 0 0 / 20%);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    top: 85px;
    padding-bottom: 100px;

    @include screen.media-fluid(md) {
      top: 0;
      padding-bottom: 16px;
    }

    &__title {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #000000;
      margin-bottom: 24px;
    }

    &__content {
      max-height: 379px;
      overflow: hidden;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: justify;
      color: #000000;
      overflow-y: scroll;
      margin-bottom: 24px;
    }

    &__actions {

      &__accept-terms {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        margin-bottom: 24px;

        .form-control {
          display: flex;
          align-items: center;
          justify-content: start;
        }

        input[type=checkbox] {
          border:solid 1px #282A3766;
          border-radius: 4px;
          margin-top: 30px;
        }
        
        input[type=checkbox] {
          display: inline-block;
          -webkit-appearance: none;
          appearance: none;
          background-color: #fff;
          margin: 0;
          font: inherit;
          color: currentColor;
          width: 24px;
          height: 24px;
          border: 1px solid #000000;
          border-radius: 4px;
          transform: translateY(-0.075em);
          margin-right: 10px;
          display: grid;
          place-content: center;
        }
        
        input[type=checkbox]::before {
          position: relative;
          top: 1px;
          left: 3px;
          content: "";
          width: 24px;
          height: 17px;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          background-image: url("./../../../assets/icons/checkbox-check.svg");
          background-repeat: no-repeat;
        }
        
        input[type=checkbox]:checked {
          border: 1px solid #000000;
          background-color: #FFFF00;
        }
        
        input[type=checkbox]:checked::before {
          transform: scale(1);
        }

      }

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: end;

        button {
          border: none;
          background: transparent;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.0125em;
          text-transform: uppercase;
          color: #A7A8A9;
          padding: 0 14px;

          &.primary {
            color: #2F80ED;
          }

          &:disabled {
            color: #A7A8A9;
          }
        }
      }
    }
  }
}