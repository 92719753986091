$bees-grey: #F0ECFC;

.card {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);



  & h1 {
    font-size: 16px;
    font-weight: 700;
    padding: 16px;
    border-bottom: 1px solid $bees-grey;
    margin-bottom: 0;
  }

  &__action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 51px;
    border-bottom: 1px solid $bees-grey;
    padding-right: 10px;
    cursor: pointer;

    & div {
      display: flex;
      align-items: center;
      padding-left: 20px;

      & span {
        margin-right: 10px;
      }
    }
  }

  &__row {
    padding-left: 16px;
    padding-top: 20px;
    font-size: 16px;

    & h5 {
      font-size: 12px;
      color: #707372;
      font-weight: 400;
      margin: 0;
    }

    &--black {
      border-top: 5px solid #a7a8a9;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      padding-left: 1rem;
      padding-block: 10px;

      & h4 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }

      & div {
        font-size: 20px;
        font-weight: 700;
        padding-top: 10px;
      }

      & button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 1rem;
        color: #546DD4;
      }
    }

    &--yellow {
      border-top: 5px solid #a7a8a9;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      padding-left: 1rem;
      padding-block: 10px;
      background-color: #f4f4f4;

      & button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 1rem;
        color: #546DD4;
      }

      & h4 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }

      & div {
        font-size: 20px;
        font-weight: 700;
        padding-top: 10px;
      }
    }
  }
}
