@use "../../../styles/base/size-screen" as screen;
$widthMobile: map-get(screen.$grid-breakpoints, xs);


.MuiPaper-root {
    margin-top: 0px;
}

.container {
  background-color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 100%;
  min-height: 273px;

  h1 {
    font-size: 16px;
    margin: 0;
    padding: 1rem !important;
  }

  p {
    h2 {
      font-size: 14px;
      font-weight: 400;
      margin: 0px;
      padding: 1rem !important;
    }
  }

  &_h100 {
    height: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 46px;
    border-bottom: 1px solid #F0ECFC;

    h2 {
      font-size: 14px;
      font-weight: 400;
      padding-right: 20px;
      width: 100%;
    }

    span {
      width: 30px !important;
    }
  }
}

.body {
  margin-top: 0;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-block: 25px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    margin-top: -106px;

    h1 {
      margin: 0;
      font-size: 20px;
      margin-top: 20px;
    }

    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 180px;
  background-image: url('../../../assets/icons/icon-bee.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 28px;
  background-color: #F9F8FF;
  margin-inline: auto;

  > span {
    position: relative;
    top: -162px;
    margin-left: -75px;
  }
}


