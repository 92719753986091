@use "../../../styles/base/size-screen" as screen;
.faq .MuiPaper-root {
  margin-top: 0px !important;
  max-width: 100% !important;
  height: auto !important;
  max-height: 100% !important;
}

@include screen.media-fluid(lg) {
  .faq {
    padding: 40px;
  }

  .faq h1 {
    font-size: 28px !important;
  }

  .faq h2 {
    font-size: 18px !important;
  }
}

