.container {
  position: fixed;
  display: grid;
  grid-template-columns: 244px 4fr;

  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  &__rigth {
    max-height: 100%;
    overflow: auto;
  }
}