$bees-grey: #F0ECFC;
.dialog {
  margin-top: 56px;
}

.card {
  & h1 {
    font-size: 24px;
    font-weight: 700;
    padding: 0 16px 16px 16px;
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 16px;
    background-color: white;
    margin-top: -1px;
    position: sticky;
    top: 0;
    z-index: 5;
  }

  &__body {
    padding-bottom: 100px;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    background-color: $bees-grey;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  &__info_row {
    border-bottom: 1px solid $bees-grey;
    padding-inline: 16px;
    padding-bottom: 16px;
    font-size: 16px;


    & h4 {
      margin-block: 12px;
    }

    & h5 {
      font-size: 12px;
      color: #707372;
      font-weight: 400;
      margin: 0;
    }
  }

  &__footer {
    font-size: 12px;
    padding-top: 12px;
    padding-inline: 16px;
  }
}
