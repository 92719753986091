@use "../../../styles/base/size-screen" as screen;
$grey1: #707372;
$grey2: #F0ECFC;
$yellow1: #E08200;
$beesRed: #FF6F71;

.hideOnMobile {
  display: none;

  @media screen and (min-width: 600px) {
    display: block;
  }
}


.container{

  background-color: white;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: black;
    text-align: center;
    height: 100px;
    color: white;

    h1 {
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      margin-block: 5px;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
  }

  &__warning{
    > span {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #707372;
      margin: 10px;

      @include screen.media-fluid(md) {
        text-align: left;
      }
    }
  }

    &__body {

    &--hide {
      display: none;
    }

    &__row {
      display: flex;
      flex-direction: row;
      height: 40px;
      justify-content: space-between;
      align-items: center;
      padding-inline: 20px;
      border-bottom: 1px solid $grey2;

      h2 {
        color: black;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }

      h3{
        font-size: 16px;
        font-weight: 400;
        color: $grey1;
        margin: 0;
      }

      &--group {
        border-bottom: 1px solid $grey2;
        padding-inline: 20px;

        span:nth-child(1) {
          padding: 0;
        }

        span {
          border: none;
          height: 30px;
          padding-right: 0;
        }
      }

      &--red {
        h2 {
          color: $beesRed;
        }
      }

      &--yellow {
        h2 {
          color: $yellow1;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    .btn{
      background-color: #00000000;
      margin: auto;
      margin-bottom: 20px;

      &__help{
        display: block;
        font-size: 16px;
        border: none;
        color: #546DD4;
        height: 40px;

        @media screen and (min-width: 600px) {
          display: none;
        }
      }

      &__payment {
        background: #FFFFFF;
        border: 1px solid #000000;
        font-size: 14px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        width: 200px;
        height: 40px;
      }
    }
  }
}

.creditCard {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid orange;
  margin-block: 20px;
  padding: 20px;

  h3 {
    margin: 0;
    margin-bottom: 20px;
  }

  span {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h1 {
      font-size: 16px;
      font-weight: 400;
      border-bottom: 0px;
      padding: 0px;
      margin: 0;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
  }

  .progress {
    background-color: black;
    height: 8px;
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    border-radius: 4px;

    span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 16px;
        font-weight: 400;
        border-bottom: 0px;
        padding: 0px;
        margin: 0;
      }

      h5 {
        font-size: 10px;
        font-weight: 400;
        margin: 0;
      }
    }

    &__bar__yellow {
      background-size: 8px;
      height: 100%;
      position: relative;
      background-color: yellow;
      animation: cssload-width 2s cubic-bezier(0.45, 0, 1, 1);
      width: 50%;
      border-radius: 4px;
    }

    @for $i from 0 through 100 {
      &__bar__#{$i} {
        animation: cssload-width-#{$i} .5s cubic-bezier(0.45, 0, 1, 1);
        width: calc(#{$i} * 1%);
      }
    }
  }

  @for $i from 0 through 100 {
    $keyframe: percentage($i);

    @keyframes cssload-width-#{$i} {
      0%, #{$keyframe} {
          transition-timing-function: cubic-bezier(.5, 0, 0.65, 0);
      }
      0% {
          width: 0;
      }

      #{$keyframe}  {
          width: #{$keyframe};
      }
    }
  }


}
