.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 10px;
  color: #546DD4;
  background-color: white;
  border-bottom: 1px solid #F0ECFC;

  h1 {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    text-transform: capitalize;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
}