$bees-grey: #F0ECFC;
.dialog {
  margin-top: 56px;
}

.info {
  display: flex;
  align-items: center;
  padding: 10px;

  ::before {
    content: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM9.03488 5.07675C9.03488 5.02152 9.07965 4.97675 9.13488 4.97675H10.8651C10.9203 4.97675 10.9651 5.02152 10.9651 5.07675V6.80698C10.9651 6.86221 10.9203 6.90698 10.8651 6.90698H9.13488C9.07965 6.90698 9.03488 6.86221 9.03488 6.80698V5.07675ZM9.13488 8.94185C9.07965 8.94185 9.03488 8.98662 9.03488 9.04185V14.7372C9.03488 14.7924 9.07965 14.8372 9.13488 14.8372H10.8651C10.9203 14.8372 10.9651 14.7924 10.9651 14.7372V9.04185C10.9651 8.98662 10.9203 8.94185 10.8651 8.94185H9.13488Z' fill='%2356CCF2'/%3E%3C/svg%3E");
    padding: 10px;
  }
}

.card {
  & h1 {
    font-size: 24px;
    font-weight: 700;
    padding: 0 16px 16px 16px;
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 16px;
    background-color: white;
    margin-top: -1px;
    position: sticky;
    top: 0;
    z-index: 5;
  }

  &__body {
    padding-bottom: 100px;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    background-color: $bees-grey;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  &__info_row {
    border-bottom: 1px solid $bees-grey;
    padding-inline: 16px;
    padding-bottom: 16px;
    font-size: 16px;


    & h4 {
      margin-block: 12px;
    }

    & h5 {
      font-size: 12px;
      color: #707372;
      font-weight: 400;
      margin: 0;
    }
  }


  &__footer {
    font-size: 12px;

    &_icon {
      display: flex;
      align-items: center;
      padding: 10px;

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM9.03488 5.07675C9.03488 5.02152 9.07965 4.97675 9.13488 4.97675H10.8651C10.9203 4.97675 10.9651 5.02152 10.9651 5.07675V6.80698C10.9651 6.86221 10.9203 6.90698 10.8651 6.90698H9.13488C9.07965 6.90698 9.03488 6.86221 9.03488 6.80698V5.07675ZM9.13488 8.94185C9.07965 8.94185 9.03488 8.98662 9.03488 9.04185V14.7372C9.03488 14.7924 9.07965 14.8372 9.13488 14.8372H10.8651C10.9203 14.8372 10.9651 14.7924 10.9651 14.7372V9.04185C10.9651 8.98662 10.9203 8.94185 10.8651 8.94185H9.13488Z' fill='%2356CCF2'/%3E%3C/svg%3E");
        padding: 10px;
      }
    }

  }
}
