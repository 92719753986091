.login {
  padding-top: 40px;

  &__logo {
    background-image: url('./../../assets/images/bees-logo-login.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 116px;
    height: 100px;
    margin: 40px auto;
  }

  &__form {
    width: 300px;
    margin: 0 auto;
    --maz-tu-info-bees-input-margin-bottom: 27px;
  }

  &__button {
    width: 100%;
    text-align: center;
    --maz-tu-info-bees-button-width: 200px;
    margin-bottom: 60px;
  }

  .maz-tu-info-bees-input:nth-child(2) {
    margin-bottom: 36px !important;
  }
}

.registry__footer {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  margin-bottom: 100px;

  &__btn{
    background-color: #00000000;
    margin: auto;
    margin-bottom: 20px;

    &__help{
      display: block;
      font-size: 16px;
      border: none;
      color: #000000;
      height: 40px;
      background-color: transparent;
    }

    &__payment {
      background: #FFFFFF;
      border: 1px solid #000000;
      font-size: 14px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      width: 200px;
      height: 40px;
      align-self: center;
    }
  }
}
