.blockBMax {
  position: fixed;
  top: 0px;
  bottom:0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  flex-direction: column;
  backdrop-filter: blur(5px);
}

.containerbMaxModal {
  position: fixed;
  top: 0px;
  bottom:0px;
  left: 0px;
  right: 0px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  flex-direction: column;
  z-index: 20;
  padding: 10px;
}

.modalDetails {
    width: 100%;
    max-width: 500px;
    height: auto;
    max-height: 500px;
    border-radius: 15px;
    padding: 15px;
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalHeaderBMax {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
}


.logo_clubBMax {
  width: 100px;
  height: 100px;
  bottom: 150px;
  display: flex;
  justify-content: center;
}

.titleStyle {
  font-weight: 700;
  font-size: 24px;
  color: black;
}
