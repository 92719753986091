$gridTemplate: 150px 350px 250px 350px 200px 350px 200px 100px;
$minWidth: 1200px;

.container {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  font-size: 16px;

  .header {
    display: grid;
    grid-template-columns: $gridTemplate;
    grid-gap: 10px;
    padding-block: 10px;
    min-width: $minWidth;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      span {
        display: flex;
        align-items: center;
      }
    }

    h1 {
      font-size: 12px;
      font-weight: 700;
      color: black;
      margin: 0;
    }
  }

  .body {
    min-width: $minWidth;
  }

  .row {
    display: grid;
    grid-template-columns: $gridTemplate;
    grid-gap: 10px;
    padding-block: 10px;

    > div {
      box-shadow: inset 0px -1px 0px #EAEAEA;
    }
  }

  .center {
    text-align: center;
  }
}

.paginator {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 50px;
}
