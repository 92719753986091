@use "../../styles/base/size-screen" as screen;

$bees-grey: #F0ECFC;
$bcDeg1: linear-gradient(89.63deg, #003E9B -39.62%, #4AF4FF 114.28%);
$bcDeg2: linear-gradient(79.77deg, #96E2E2 15.62%, #B2FCFB 82.01%);
$bcDeg3: linear-gradient(79.77deg, #CD5956 15.62%, #ED7874 82.01%);

.container {
  .banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 180px;
    background-image: url('../../assets/icons/icon-bee.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 28px;
    background-color: #F9F8FF;
    margin-inline: auto;

    > span {
      position: relative;
      top: -162px;
      margin-left: -75px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;
    padding-bottom: 70px;
    width: 100%;

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-block: 25px;
      width: map-get(screen.$grid-breakpoints, xs);
      margin-inline: 10px;
      background-color: white;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      margin-top: -145px;

      @include screen.media-fluid(md) {
        width: map-get(screen.$grid-breakpoints, md);
      }

      h1 {
        margin: 0;
        font-size: 20px;
        margin-top: 20px;
      }

      h4 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: map-get(screen.$grid-breakpoints, xs);
      grid-gap: 16px;
      margin-top: 10px;
      padding: 0;

      @include screen.media-fluid(md) {
        width: map-get(screen.$grid-breakpoints, md);
        grid-gap: 36px;
      }
    }
  }
}

