.brochuere {

  .maz-tu-info-bees-title {
    margin-bottom: 34px;
    padding: 0 !important;
    margin-top: 19px;
  }

  .maz-tu-info-bees-button {
    margin-bottom: 24px;
  }

  &__image {
    margin-bottom: 15px;

    img {
      width: 100%;
    }
  }
}