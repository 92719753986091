.maz-tu-info-bees-contract {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 14px;
  position: relative;
  padding-top: 5px;
  margin-bottom: 16px;
  overflow: hidden;

  &__title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 15px;
  }

  &__contract-number {
    position: absolute;
    top: 14px;
    right: 14px;

    &__label {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      color: #000000;
    }

    &__number {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      color: #000000;
    }
  }

  &__dates {
    display: table;
    width: 100%; 

    &__info {
      display: table-cell;
      width: 49%;
      background-color: #F0ECFC;
      text-align: center;
      padding: 3px 0;

      &__label {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #000000;
      }

      &__date {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #000000;
      }
    }

    &__separator {
      display: table-cell;
      width: 2%;
    }

  }
}