
/* ~~~~~~~~~~~~~~~~~~ variables.css ~~~~~~~~~~~~~~~~ */

:root {
  /* ============================= Colors ============================ */

  --color-main: #c50006;
  --color-gray-50-light: #ffffff;
  --color-gray-50: #fafafa;
  --color-gray-50-dark: #c7c7c7;
  --color-gray-100-light: #ffffff;
  --color-gray-100: #f5f5f5;
  --color-gray-100-dark: #c2c2c2;
  --color-gray-200-light: #ffffff;
  --color-gray-200: #eeeeee;
  --color-gray-200-dark: #bcbcbc;
  --color-gray-300-light: #ffffff;
  --color-gray-300: #e0e0e0;
  --color-gray-300-dark: #aeaeae;
  --color-gray-400-light: #efefef;
  --color-gray-400: #bdbdbd;
  --color-gray-400-dark: #8d8d8d;
  --color-gray-500-light: #9e9e9e;
  --color-gray-500: #9e9e9e;
  --color-gray-500-dark: #707070;
  --color-gray-600-light: #a4a4a4;
  --color-gray-600: #757575;
  --color-gray-600-dark: #494949;
  --color-gray-700-light: #8e8e8e;
  --color-gray-700: #616161;
  --color-gray-700-dark: #373737;
  --color-gray-800-light: #6d6d6d;
  --color-gray-800: #424242;
  --color-gray-800-dark: #1b1b1b;
  --color-gray-900-light: #484848;
  --color-gray-900: #212121;
  --color-gray-900-dark: #000000;
  /* Source: https://material.io/resources/color/ */

  --color-gray-lighter: #e9e9e9;
  --color-gray-light: var(--color-gray-100);
  --color-gray: var(--color-gray-500);
  --color-gray-dark: var(--color-gray-900);
  --color-error: #d32f2f;
  --color-warning: #f9a825;
  --color-success: #4caf50;
  /* Source: https://material.io/design/color/applying-color-to-ui.html */

  --color-facebook-app: #1778f2;
  /* Source: https://en.facebookbrand.com/ */

  --color-twitter-primary: #1DA1F2;
  /* Source: https://about.twitter.com/en_us/company/brand-resources.html */

  --color-linkedin: #3861b6;
  /* Source: https://brand.linkedin.com/policies */
  /* ======================== TRANSITIONS ======================== */

  --timing-small: 150ms;
  --timing-medium: 300ms;
  --timing-large: 500ms;
  --easing-standard: cubic-bezier(0.4, 0.0, 0.2, 1); /* Material Standard easing */
  --easing-decelerated: cubic-bezier(0.0, 0.0, 0.2, 1); /* Material Decelerated easing */
  --easing-accelerated: cubic-bezier(0.4, 0.0, 1, 1); /* Material Accelerated easing */
  /* Source: https://material.io/design/motion/speed.html#easing */

  --transition-small: var(--easing-standard) var(--timing-small);
  --transition-medium: var(--easing-standard) var(--timing-medium);
  --transition-large: var(--easing-standard) var(--timing-large);
  --transition-decelerated-small: var(--easing-decelerated) var(--timing-small);
  --transition-decelerated-medium: var(--easing-decelerated) var(--timing-medium);
  --transition-decelerated-large: var(--easing-decelerated) var(--timing-large);
  --transition-accelerated-small: var(--easing-accelerated) var(--timing-small);
  --transition-accelerated-medium: var(--easing-accelerated) var(--timing-medium);
  --transition-accelerated-large: var(--easing-accelerated) var(--timing-large);
  /* Support for old transitions vars name */
  --transition-short: var(--transition-small);
  --transition-med: var(--transition-medium);
  --transition-long: var(--transition-large);
  /* ======================= Fonts ======================== */

  --font-headings: 'Roboto', serif;
  --font-body: 'Roboto', sans-serif;
  --font-monospace: 'Roboto Mono', monospace;
  /* ======================= Shadows ======================== */

  --shadow-2dp: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12); /* 2dp Material Shadow */
  --shadow-4dp: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12); /* 4dp Material Shadow */
  --shadow-6dp: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12); /* 6dp Material Shadow */
  --shadow-12dp: 0 7px 8px -4px rgba(0,0,0,.2),0 12px 17px 2px rgba(0,0,0,.14),0 5px 22px 4px rgba(0,0,0,.12); /* 12dp Material Shadow */
  /* Support for old shadow vars name */
  --shadow-lg: var(--shadow-12dp);
  --shadow-ms-depth-4: 0 2px 4px 0 rgba(0,0,0,.132),0 0px 1px 0 rgba(0,0,0,.108);
  --shadow-ms-depth-8: 0 3px 7px 0 rgba(0,0,0,.132),0 1px 2px 0 rgba(0,0,0,.108);
  --shadow-ms-depth-16: 0 6px 14px 0 rgba(0,0,0,.132),0 1px 4px 0 rgba(0,0,0,.108);
  --shadow-ms-depth-64: 0 26px 58px 0 rgba(0,0,0,.22),0 5px 14px 0 rgba(0,0,0,.18);
  /* ============================== mdc ==============================  */

  --mdc-theme-primary: var(--color-main) !important;
}
