.maz-tu-info-bees-card-points {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px 16px;
  box-sizing: border-box;
  margin-bottom: 32px;
  text-align: center;

  .maz-tu-info-bees-button {
    max-width: 240px;
    margin-top: 9px;
  }

  &__total {
    margin-bottom: 25px;

    &__points {
      font-family: 'Barlow Semi Condensed';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.03em;
      color: #000000;
      text-align: center;

      span {
        font-family: 'Barlow Semi Condensed';
        font-style: normal;
        font-weight: 600;
        font-size: 56px;
        line-height: 56px;
        letter-spacing: -0.03em;
        color: #000000; 
      }
      
    }

    &__label {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #000000;
    }

  }

  &__detail {
    border-bottom: 1px solid #C6C7C7;
    margin-bottom: 16px;

    &__status {
      margin-bottom: 4px;

      ul {
        padding: 0;
        margin: 0;
        margin-left: 16px;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
      }

      &--error {
        ul {
          color: #FF6F71;
        }
      }

      &--progress {
        ul {
          color: #FFB042;
        }
      }

      &--success {
        ul {
          color: #6FCF97;
        }
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__label {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #000000;
        position: relative;
        top: -3px;
      }

      &__points {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
        padding: 2px 8px;
        box-sizing: border-box;
        background: #E5E6E6;
        border-radius: 16px;
        margin-bottom: 8px;
      }
    }
  }
  
}