.contrato {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 8px;
  box-sizing: border-box;
  background-color: #ffffff;

  .maz-tu-info-bees-title {
    margin-bottom: 16px;
  }

  .maz-tu-info-bees-columns-info {
    margin-bottom: 24px;
  }
}