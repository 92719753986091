$yellow: #ffff00;

.submenu {
  &[open] {
    summary {
      > span {
        background-image: url('../../../../../assets/icons/icon-arrow-upper-white.svg');
      }
    }

    &.active{
      summary {
        > span {
          background-image: url('../../../../../assets/icons/icon-arrow-upper-yellow.svg');
        }
      }
    }
  }

  summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style: none;

    li {
      background-color: black !important;
    }

    > span {
      margin-top: 10px;
    }
  }

  span {
    background-size: 15px;
    background-position: center;
  }

  h5 {
    margin-left: 20px;
    text-transform: uppercase;
  }
}