$grey: #ebebeb;

.container {
  width: 100%;
  border-radius: 4px 4px 0 0;
  padding-block: 20px;
  background-color: white;
  border-bottom: 1px solid #F0ECFC;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-inline: 15px;
    background-color: $grey;
    border-radius: 8.91px;
    border: 2px solid $grey;
    align-items: center;

    h1 {
      margin: 0;
      color:  rgba(60, 60, 67, 0.36);
      font-size: 15px;
    }
  }

  button {
    font-size: 13px;
    font-weight: 600;
    height: 30px;
    width: 100%;
    border: 0;
    background-color: $grey;

    &.active {
      background: #FFFFFF;
      border: 0.5px solid $grey;
      box-shadow: 0px 3px 8px $grey, 0px 3px 1px $grey;
      border-radius: 6.93px;
    }
  }
}
