.maz-tu-info-bees-gallery {
  margin-bottom: 40px;

  &__title {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    &__counter {
      display: inline-block;
      border-radius: 4px;
      padding: 1px 9px;
      color: #ffffff;

      &--not-accepted {
        background: #FF6F71;
      }

      &--progress {
        background: #FFB042;
      }

      &--completed {
        background: #6FCF97;
      }
    }
  }

  &__carousel {
    padding: 6px;
    overflow: hidden;
    max-width: none;
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    padding-top: 5px;
    overflow-x: scroll;

    &__list {
      display: flex;
      justify-content: space-evenly;
      max-width: none;
    }
  }

}