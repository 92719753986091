.body {
  background-color: white;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  &__card {
    background-color: white;
    width: 100%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    height: 100%;

    &__content {
      height: calc(100% - 66px);
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      font-size: 12px;
    }

    h1 {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      line-height: 16.42px;
      margin-bottom: 8px;
    }

    &__description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;

      div {
        margin-top: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }

    button {
      text-align: end;
      width: 100%;
      background-color: white;
      border: none;
      cursor: pointer;
      padding: 0px 10px 10px;
    }

    &__banner {

      &__1::after {
        background-image: url("../../../assets/icons/IconPuntosY.svg");
      }

      &__1.headerCard__black::after {
        background-image: url('../../../assets/icons/IconPuntos.svg');
      }

      &__2::after {
        background-image: url('../../../assets/icons/IconMiCuenta.svg');
      }

      &__3::after {
        background-image: url('../../../assets/icons/IconEstadoCuenta.svg');
      }

      &__support::after {
        background-image: url('../../../assets/icons/IconAyuda.svg');
      }

      &__support-exclamation::after {
        background-image: url('../../../assets/icons/IconAyudaEx.svg');
      }

      &__5::after {
        background-image: url('../../../assets/icons/IconLogout.svg');
      }
    }
  }

  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 66px;
    background: url('../../../assets/icons/home-finance-max-degradient.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 135px;
    padding: 10px;

    h1 {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }

    div {
      margin-top: 5px;
      font-size: 12px;
      font-weight: normal;
    }

    button {
      text-align: end;
      margin-top: 10px;
      width: 100%;
      background-color: white;
      border: none;
      cursor: pointer;
    }
  }
}

.headerCard {
  padding-top: 66px;

  &__max {
    background-color: #FFFF00;
  }

  &__black {
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(95, 95, 95) 30%, rgb(148, 148, 148) 50%, rgb(104, 104, 104) 74%, rgb(0, 0, 0) 100%);
  }

  &::after {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 50px;
    content: "";
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
}
