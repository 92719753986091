.navbar {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.navbar-toggler {
  position: fixed;
  top: 10px;
  right: 10px;
}

.maz-tu-info-bees-header {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  position: sticky;
  z-index: 10;
  top: 0;

  &__logo {
    background-image: url('./../../../assets/images/bees-logo-header.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 112px;
    height: 17px;
    margin: 20px 0;
  }

  @media screen and (max-width: 979px) {
    &__user-info {
      padding: 10px 20px;

      &_finance {
        background-color: #EAEAEA;
      }
    }
  }

  &__back-navigation ~ &__logo {
    display: none;
  }

  @media screen and (min-width: 980px) {
    &__logo {
      height: 24px;
      width: 170px;
      background-size: contain;
    }

    &__back-navigation {
      display: none !important;
    }

    &__back-navigation ~ &__logo {
      display: block !important;
    }
  }

  &__back-navigation {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 60px;
  }

  &__user-name {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #000000;
    margin-bottom: 4px;
    max-height: 14px;
    overflow: hidden;
  }

  &__user-id {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #000000;
  }
}
