$greyC4: #c4c4c4;

.containerEndContracts {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: white;
  height: 100%;
  &__body {
    height: calc(100% - 65px);
    overflow: auto;
    background-color: white;
    margin: 0;
    margin-top: 2px;
    &__padding {
      padding: 50px 50px 0 50px;
    }
  }
}

.btn {
  cursor: pointer;
  border-radius: 4px;
  height: 40px;
  width: 200px;
  margin: 10px;

  &--black{
    color: white;
    background-color: black;
  }

  &__container {
    display: flex;
    flex-direction: row;
  }
}
