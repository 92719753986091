.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 30px;
  padding-inline: 5px;

  > input {
    height: 40px;
    font-size: 20px;
    padding-left: 10px;
  }

  > label {
    font-size: 18px;
    font-weight: 500;
    padding: 5px;
  }
}
