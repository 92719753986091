.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0;

  >.bold {
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.triangleUp {
  position: relative;
  top: 2px;
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-right: 0.1em solid green;
  border-top: 0.1em solid green;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
}

.triangleGroup {
  display: flex;
  flex-direction: column;
  right: 8px;
}

.highlighted {
  right: -30px;
}
