.maz-tu-info-bees-resume-totals {
  background: #ffff00;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 4px 20px;
  position: fixed;
  bottom: 70px;
  width: calc(100% - 16px);
  left: 8px;
  box-sizing: border-box;

  &__title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 0 6px;
    margin-top: 5px;
  }

  &__subtitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 0 6px;
    margin-bottom: 11px;
  }

  &__row {
    width: 100%;
    border-bottom: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 9px 36px;
    padding-left: 6px;

    &:last-child {
      border: 0;
    }

    span.label {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #000000;

      &--bold {
        font-weight: 700;
      }
    }

    span.value {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      color: #000000;
    }
  }
}
