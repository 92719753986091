.maz-tu-info-bees-button {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  border: 0;
  width: var(--maz-tu-info-bees-button-width, 100%);
  height: var(--maz-tu-info-bees-button-height, 40px);

  &.primary {
    background: #000000;
    color: #FFFFFF;
  }

  &.primary-outline {
    border: 1px solid #000000;
    background: #FFFFFF;
    color: #000000;
  }

  &.secondary {
    background: #FFFF00;
    color: #000000;
  }

  &:disabled {
    background: #A7A8A9;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

}
